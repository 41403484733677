/* global etabwebsitejsvars, Waypoint */

import Glide from '@glidejs/glide';
import TableFilter from 'tablefilter';
import '@fancyapps/fancybox';
import 'bootstrap';
import 'waypoints/lib/jquery.waypoints';
import 'waypoints/lib/shortcuts/inview';
import 'waypoints/lib/shortcuts/sticky';
import 'jquery-zoom';

import './navigation';
import './skip-link-focus-fix';
import './jquery.auto-complete';

jQuery( function( $ ) {
	'use strict';

	// mobile nav toggle
	$( function() {
		$( '.navbar-toggler' ).click( function() {
			$( '.navbar-toggler, .mobile-nav' ).toggleClass( 'is-active' );
			$( 'body' ).toggleClass( 'mobilenav-open' );
		} );
	} );

	// language switcher on mobile
	if ( $( window ).width() < 1199 ) {
		$( '.language-dropdown .active' ).click( function( event ) {
			event.preventDefault();
			event.stopPropagation();

			$( '.language-dropdown ul' ).addClass( 'hovered' );
			$( '.language-dropdown .inactive' ).show();

			$( document ).one( 'click', function( e ) {
				if ( $( '.language-dropdown' ).has( e.target ).length === 0 ) {
					$( '.language-dropdown .inactive' ).hide();
					$( '.language-dropdown ul' ).removeClass( 'hovered' );
				} else {
					$( document ).one( 'click' );
				}
			} );
		} );
	}

	// sticky header
	if ( $( window ).width() > 1199 ) {
		new Waypoint.Sticky( {
			element: $( '.nav-sticky' )[ 0 ],
			offset: -20,
		},
		$( '.hodl-this' ).addClass( 'container' )
		);
	}

	if ( $( window ).width() < 1199 ) {
		new Waypoint.Sticky( {
			element: $( '#header' )[ 0 ],
			offset: -70,
		} );
	}

	// sticky products category nav
	$( '.products-nav' ).stickThis( {
		top: 140,
		minscreenwidth: 768,
		pushup: '.block-cta',
	} );

	// sticky page nav
	if ( $( '.page-nav' ).length ) {
		new Waypoint.Sticky( {
			element: $( '.page-nav' )[ 0 ],
			offset: 110,
		} );
	}

	// search desktop
	const searchTrigger = $( '#btn-search' );
	const searchSubmit = $( '.service-menu .search-submit' );
	const searchPlaceholder = searchTrigger.attr( 'placeholder' );

	searchTrigger.attr( 'placeholder', '' );

	searchTrigger.focus( function( ) {
		$( this ).attr( 'placeholder', searchPlaceholder );
		searchSubmit.show();
	} );

	searchTrigger.blur( function( event ) {
		if ( event.relatedTarget || $( event.relatedTarget ).is( '#header-search-desktop' ) ) {
			if ( $( '#header-search-desktop' )[ 0 ].checkValidity() ) {
				$( '#header-search-desktop' ).submit();
			}
		}
		$( this ).attr( 'placeholder', '' );
		$( this ).val( '' );
		searchSubmit.hide();
	} );

	// autofocus input on modal open
	$( '#searchModal' ).on( 'shown.bs.modal', function() {
		$( '.input-modal' ).trigger( 'focus' );
	} );

	/* eslint-disable */
	// Main function for search
	$( '.search-autocomplete' ).autoComplete( {
		minChars: 2,
		source( term, response ) {
			$.getJSON(
				etabwebsitejsvars.ajaxurl,
				{
					action: 'etabwebsite_ajax_search',
					searchTerm: term,
					lang: etabwebsitejsvars.lang,
				},
				function( data ) {
					response( data );
				}
			);
		},
		renderItem( item, search ) {
			search = search.replace( /[-\/\\^$*+?.()|[\]{}]/g, '\\$&' );
			RegExp( '(' + search.split( ' ' ).join( '|' ) + ')', 'gi' );
			return '<div class="autocomplete-suggestion" data-href="' + item[ 0 ] + '" data-sku="' + item[ 2 ] + '" data-val="' + search + '">' + item[ 1 ] + '</div>';
		},

		onSelect( e, term, item ) {
			window.location.href = item.data( 'href' );
			//console.log( e.type );
		},
	} );

	// product gallery and media blocks
	if ( $( '#glide-products' )[ 0 ] ) {
		new Glide( '#glide-products', {
			type: 'carousel',
			focusAt: 'center',
		} ).mount();
	}

	if ( $( '.glide-block' )[ 0 ] ) {
		new Glide( '.glide-block', {
			type: 'carousel',
			focusAt: 'center',
		} ).mount();
	}

	if ( $( '#services-slider-home' )[ 0 ] ) {
		new Glide( '#services-slider-home', {
			focusAt: 'center',
			perView: 1,
		} ).mount();
	}

	//Single product images slider
	if ( $( '.wpis-slider-for' ).length ) {
		$( '.wpis-slider-for' ).slick( {
			fade: true,
			autoplay: false,
			arrows: true,
			slidesToShow: 1,
			infinite: true,
			slidesToScroll: 1,
			dots: true,
		} );

		$( '.wpis-slider-for .slick-track' ).addClass( 'woocommerce-product-gallery__image single-product-main-image' );

		$( '.variations select' ).on( 'change', function() {
			$( '.wpis-slider-for' ).slick( 'slickGoTo', 0 );
			window.setTimeout( function() {
				//$( '.wpis-slider-for .slick-track .slick-current' ).zoom();
			}, 20 );
		} );
	}

	/* Pushing the state to history */
	function pushStateToHistory( category, sort, postPerPage, offset ) {
		const $categorySlug = $( '#category-slug' );
		let hostName = $categorySlug.data( 'shop-base' );

		if ( 'all' !== category ) {
			const categoryBase = $categorySlug.data( 'category-base' );
			/* Needed to determine if page is en or sv */
			if ( $categorySlug.data( 'shop-base' ).includes( '/en/' ) ) {
				hostName = `//${ window.location.hostname }/en/${ categoryBase }/${ category }/`;
			} else {
				hostName = `//${ window.location.hostname }/${ categoryBase }/${ category }/`;
			}
		}

		/* Pushing state with necessary variables to load products on the addEventListener */
		history.pushState( { // eslint-disable-line no-undef
			currentCategory: category,
			sortingType: sort,
			nrPostsPerPage: postPerPage,
			productsOffset: offset,
		}, 'null', hostName );
	}

	// change category on mobile for load more products
	$( '.category-nav' ).on( 'change', function( ) {
		$( '.load-more-products-link' ).hide();
		const category = $( 'option:selected', $( this ) ).val();
		const $productsCards = $( '.products-cards' );
		const postPerPage = $productsCards.data( 'posts-per-page' );
		const sort = $( 'option:selected', $( '.products-sort-byname' ) ).val();
		const offset = 0;

		$productsCards.data( 'last-category', category );
		$( '.products-cards > div.products-list' ).html( '' );

		/* Pushing state to history with current ajax settings */
		pushStateToHistory( category, sort, postPerPage, offset );
		loadCategoryContent();
		if ( 'all' !== $( 'option:selected', $( this ) ).val() ) {
			loadProducts( category, sort, postPerPage, offset );
		} else {
			loadProducts( category, sort, -1, offset );
		}

		const blockAreas = $( '.block-areas' );

		// Add new html to the block areas class if switching categories
		if ( blockAreas.length ) {
			blockAreas.html( '' );
			loadBlockAreas( true );
		}
	} );

	// sort for load more products
	$( '.products-sort-byname' ).on( 'change', function( ) {
		$( '.load-more-products-link' ).hide();
		// Here we fetch last category selected in container, so we would not check if we look at list or dropdown
		const $productsCards = $( '.products-cards' );
		const category = $productsCards.data( 'last-category' );
		const sort = $( 'option:selected', $( this ) ).val();
		const offset = 0;

		$( '.products-cards > div.products-list' ).html( '' );
		loadProducts( category, sort, $productsCards.data( 'posts-per-page' ), offset );
	} );

	// show all products for load more products
	$( '.show-all-link' ).on( 'click', function( event ) {
		event.preventDefault();
		const $menuLi = $( '.list-unstyled li' );
		$menuLi.each( function() {
			$( this ).removeClass( 'active' );
		} );
		$menuLi.first().addClass( 'active' );
		$( '.category-nav > option' ).removeAttr( 'selected' );
		$( '.category-nav' ).trigger( 'change' );

		const category = 'all';
		const sort = $( 'option:selected', $( '.products-sort-byname' ) ).val();
		const offset = 0;

		$( '.products-cards > div.products-list' ).html( '' );
		let selectCategory = 'Select a category';
		if ( 'sv-SE' === document.documentElement.lang ) {
			selectCategory = 'Välj kategori';
		}
		$( '.custom-selectf .custom-select-trigger' ).html( selectCategory );

		loadCategoryContent();

		loadProducts( category, sort, -1, offset );
	} );

	// change category on desktop for load more products
	$( '.product-category-link' ).on( 'click', function( event ) {
		event.preventDefault();

		$( '.load-more-products-link' ).hide();

		$( '.products-nav .list-unstyled li' ).each( function() {
			$( this ).removeClass( 'active' );
		} );

		$( this ).parent().addClass( 'active' );

		const category = $( this ).data( 'category-slug' );
		const sort = $( 'option:selected', $( '.products-sort-byname' ) ).val();
		const offset = 0;
		const firstCall = 1;
		const $productsCards = $( '.products-cards' );
		const postPerPage = $productsCards.data( 'posts-per-page' );

		sessionStorage.setItem( 'last-category', category );

		$productsCards.data( 'last-category', category );
		$( '.products-cards > div.products-list' ).html( '' );
		$( 'html, body' ).stop().animate(
			{ scrollTop: $( '.products-list' ).offset().top - 120 },
			900,
			'swing'
		);

		/* Pushing state to history with current ajax settings */
		pushStateToHistory( category, sort, postPerPage, offset );

		loadCategoryContent();
		loadProducts( category, sort, postPerPage, offset, firstCall );

		const blockAreas = $( '.block-areas' );

		// Add new html to the block areas class if switching categories
		if ( blockAreas.length ) {
			blockAreas.html( '' );
			loadBlockAreas( false );
		}

	} );

	/**
	 * Fetching data for specific category or for standard acrhive page
	 *
	 * [bool] mobile - set from where you should load the active category
	 * [string] currentCategory - override and load block for specific category. This will be used in history listener when reading previous page
	 */
	function loadBlockAreas( mobile, currentCategory = '' ) {
		const blockAreas = $( '.block-areas' );
		let isArchive = 0;
		let categorySlug = $( 'ul.list-unstyled li.active a' ).data( 'category-slug' );
		if ( mobile ) {
			categorySlug = $( '#category-nav' ).children( 'option:selected' ).val();
		}

		if ( '' !== currentCategory ) {
			categorySlug = currentCategory;
		}


		if ( 'all' === categorySlug ) {
			isArchive = 1;
		}

		$.ajax( {
			type: 'GET',
			url: etabwebsitejsvars.ajaxurl,
			dataType: 'json',
			data: {
				action: 'etabwebsite_ajax_fetch_block_areas',
				archive: isArchive,
				slug: categorySlug,
			},
			success( response ) {
				if ( blockAreas.length ) {
					blockAreas.html( '' ); // Clear current HTML content
					blockAreas.html( response.html );
				}

			},
		} );
	}

	// button for load more products
	$( '.load-more-products-link' ).on( 'click', function( event ) {
		event.preventDefault();
		$( '.load-more-products-link' ).addClass( 'is-loading' );
		// Here we fetch last category selected in container, so we would not check if we look at list or dropdown
		const $productsCards = $( '.products-cards' );
		let category = $productsCards.data( 'last-category' );
		const sort = $( 'option:selected', $( '.products-sort-byname' ) ).val();
		const offset = $( '.load-more-products' ).data( 'offset' );
		const postPerPage = $productsCards.data( 'posts-per-page' );

		/**
		 * This check is needed in those cases when you press back after entered a single product
		 * the "$productsCards.data( 'last-category' )" will alway be allcategories.
		 *
		 * When the load more function is called upon it will fetch wrong previous category.
		 * Sets the category variable to correct value.
		 *
		 * @type {jQuery}
		 */
		const currentBase = $( '#category-slug' ).data( 'category-base' );
		if ( window.location.pathname.includes( currentBase ) ) {
			$( '.products-nav .list-unstyled li' ).each( function() {
				const dataCategorySlug = $( this ).children( 'a' );
				if ( $( this ).hasClass( 'active' ) && 'all' !== dataCategorySlug.data( 'category-slug' ) ) {
					category = dataCategorySlug.data( 'category-slug' );
				}
			} );
		}

		/* Pushing state to history with current ajax settings */
		pushStateToHistory( category, sort, offset, 0 );

		loadProducts( category, sort, postPerPage, offset );
	} );

	/* Changes the active menu-item on desktop */
	function setMenuItemToActive( category ) {
		$( '.products-nav .list-unstyled li' ).each( function() {
			if ( category !== $( this ).children( 'a' ).data( 'category-slug' ) ) {
				$( this ).removeClass( 'active' );
				return;
			}
			$( this ).addClass( 'active' );
		} );
	}

	/**
	 * This function is running if you are pressing back on a tax-page but there is no history.
	 * It will reset the products to show all and reset the mobile-category-menu
	 */
	function resetShopAjax() {
		const $categorySlug = $( '#category-slug' );

		/* All product should load if you are back on the shop page */
		if ( $categorySlug.length && $categorySlug.data( 'shop-base' ) === window.location.href ) {
			/* Change back the menu-text for mobile */
			let selectCategory = 'Select a category';
			if ( 'sv-SE' === document.documentElement.lang ) {
				selectCategory = 'Välj kategori';
			}
			$( '.custom-select-trigger' ).text( selectCategory );

			/* Change back the menu-active for desktop */
			setMenuItemToActive( 'all' );

			/* Load all the products in shop */
			$( '.products-cards > div.products-list' ).html( '' );
			loadProducts( 'all', 'name', 9, 0 );

			const blockAreas = $( '.block-areas' );

			// Add new html to the block areas class if going back to "Our products / Våra produkter" => general shop page (history state)
			if ( blockAreas.length ) {
				blockAreas.html( '' );
				let isMobile = false;

				// To determine if block areas should be loaded from mobile select or if it's
				// taking the category value from desktop category menu.
				if ( 768 > $( window ).width() ) {
					isMobile = true;
				}
				// Load the default all products
				loadBlockAreas( isMobile, 'all' );
			}
		}
	}

	/**
	 * Eventlistener on back-button history
	 */
	window.addEventListener( 'popstate', function( e ) {
		const ajaxSettings = e.state;
		console.log( ajaxSettings );

		if ( null === ajaxSettings ) {
			resetShopAjax();
			return;
		}

		$( '.products-cards > div.products-list' ).html( '' );
		/* For desktop menu */
		setMenuItemToActive( ajaxSettings.currentCategory );

		/* For mobile menu */
		$( '.custom-option.level-0' ).each( function() {
			if ( ajaxSettings.currentCategory === $( this ).data( 'value' ) ) {
				$( '.custom-select-trigger' ).text( $( this ).text() );
			}
		} );

		$( '.products-cards' ).data( 'last-category', ajaxSettings.currentCategory );

		loadCategoryContent();

		loadProducts( ajaxSettings.currentCategory, ajaxSettings.sortingType, ajaxSettings.nrPostsPerPage, ajaxSettings.productsOffset );

		const blockAreas = $( '.block-areas' );
		// Add new html to the block areas class if switching categories
		if ( blockAreas.length ) {
			blockAreas.html( '' );
			let isMobile = false;

			// To determine if block areas should be loaded from mobile select or if it's
			// taking the category value from desktop category menu.
			if ( 768 > $( window ).width() ) {
				isMobile = true;
			}

			loadBlockAreas( isMobile, ajaxSettings.currentCategory );
		}
	} );
	/* Fetch the category title and descripction if slug is provided, otherwise show the default block */
	function loadCategoryContent() {
		let categorySlug = $( 'ul.list-unstyled li.active a' ).data( 'category-slug' );
		if ( 768 > $( window ).width() ) {
			categorySlug = $( '#category-nav' ).children( 'option:selected' ).val();
		}

		$.ajax( {
			type: 'GET',
			url: etabwebsitejsvars.ajaxurl,
			dataType: 'json',
			data: {
				action: 'ac_load_category_contents',
				slug: categorySlug,
			},
			success( response ) {
				$( '.block-hero-search.text-center' ).html( response );
			},
		} );
	}

	// Main function for load products
	function loadProducts( category, sort = "product_recommended", perPage , offset, firstCall = 0, isHistoryState = false ) {
		$( '.load-more-products-link' ).addClass( 'is-loading' );
		if ( !perPage ) {
			perPage = 9;
		}
		//		pushStateToHistory( category, sort, perPage, 48 );
			$.ajax( {
				type: 'GET',
				url: etabwebsitejsvars.ajaxurl,
				dataType: 'json',
				data: {
					action: 'etabwebsite_ajax_fetch_featured_products',
					category,
					sort,
					perPage,
					offset,
					lang: etabwebsitejsvars.lang,
				},
				success( response ) {
					if( firstCall == 1 ){
					$( '.products-cards > div.products-list' ).prepend( response.html );
					}

				},
			} );

				$.ajax( {
					type: 'GET',
					url: etabwebsitejsvars.ajaxurl,
					dataType: 'json',
					data: {
						action: 'etabwebsite_ajax_fetch_products',
						category,
						sort,
						perPage,
						offset,
						lang: etabwebsitejsvars.lang,
					},
					success( response ) {

						$( '.products-cards > div.products-list' ).append( response.html );
						if ( ! $.isNumeric( perPage ) ) {
							perPage = 9;
						}
						dataLayer.push({'event': 'ajax_product_load'});

						if ( ! $.isNumeric( offset ) ) {
							perPage = 9;
						}

						if ( response.count > 0 ) {

							$( '.load-more-products-link' ).show();

							if ( response.count < $( '.products-cards' ).data( 'posts-per-page' ) ) {
								$( '.load-more-products' ).hide();
							} else {
								$( '.load-more-products' ).show();
								$( '.load-more-products-link' ).removeClass( 'is-loading' );
							}
							if ( -1 === response.perPage ) {
								$( '.load-more-products-link' ).hide();
							}
						} else {
							$( '.load-more-products-link' ).hide();

							// If it's inital AJAX request, only then empty the list
							// if ( 0 === offset ) {
							// 	$( '.products-cards > div.products-list' ).html( '<div class="container"><h4>No products in this category</h4></div>' );
							// }
						}

						$( '.load-more-products' ).data( 'offset', ( parseInt( offset ) + parseInt( $( '.products-cards' ).data( 'posts-per-page' ) ) ) );
						$( '.showing-desktop' ).html( response.showing_desktop );
						$( '.showing-mobile' ).html( response.showing_mobile );

						if ( isHistoryState ) {
							$( '.load-more-products' ).data( 'offset', parseInt( perPage ) + parseInt( $( '.products-cards' ).data( 'posts-per-page' ) ) );
							sessionStorage.setItem( 'offset', perPage );
						} else {
							sessionStorage.setItem( 'offset', offset );
						}
						if( firstCall == 1 ) {

							$( ".product-item" ).each(function( index ) {
								if( index >= 9 ){
									$( this ).hide();
								}
							  });
						}
					},

					// error( error ) {
					// 	console.log( error );
					// },

					// complete( complete ) {

					// },
				} );


	}

	// auto load more when button is visible
	const loadMoreProducts = $( '.load-more-products' );
	if ( loadMoreProducts.length ) {
		const visibility = VisSense( loadMoreProducts[ 0 ], { fullyvisible: 1 } );
		visibility.monitor( {
			fullyvisible() {
				$( '.load-more-products-link' ).click();
				// console.log('visible');
			},
			hidden() {
				// console.log('hidden');
			},
		} ).start();
	}

	// For search
	$( '.show-more-results' ).on( 'click', function( event ) {
		event.preventDefault();

		const searchTerm = $( '.search-results-list' ).data( 'search-term' );
		const perPage = -1;
		const offset = $( '.show-more-results' ).data( 'offset' );

		$.ajax( {
			type: 'GET',
			url: etabwebsitejsvars.ajaxurl,
			dataType: 'json',
			data: {
				action: 'etabwebsite_ajax_fetch_search',
				searchTerm,
				perPage,
				offset,
			},
			success( response ) {
				//console.log( response );
				if ( response.count > 0 ) {
					$( '.search-results-list > div.container' ).append( response.html );
					if ( response.count < $( '.search-results-list' ).data( 'posts-per-page' ) ) {
						$( '.show-more-results' ).hide();
					}
				} else {
					$( '.show-more-results' ).hide();
				}
				$( '.show-more-results' ).data( 'offset', ( offset + $( '.search-results-list' ).data( 'posts-per-page' ) ) );
			},
			// error( error ) {
			// 	console.log( error );
			// },
			// complete( complete ) {

			// },
		} );
	} );

	// Contact form
	$( '.send-message' ).on( 'click', function( event ) {
		event.preventDefault();

		const contactForm = $( '#needs-validation' )[ 0 ];
		const name = $( '#yourname' ).val();
		const email = $( '#youremail' ).val();
		const company = $( '#yourcompany' ).val();
		const message = $( '#yourmsg' ).val();

		let sendCopy = 0;

		if ( $( '.form-check-input' ).is( ':checked' ) ) {
			sendCopy = 1;
		}

		if ( ! $( '#confirmpolicy-contact' ).is( ':checked' ) ) {
			$( '.abc-checkbox.contact .invalid-checkbox' ).show();
		}

		if ( contactForm.checkValidity() === true ) {
			$( '.send-message' ).addClass( 'is-loading' );
			$.ajax( {
				type: 'POST',
				url: etabwebsitejsvars.ajaxurl,
				dataType: 'json',
				data: {
					action: 'etabwebsite_send_contact',
					name,
					email,
					company,
					message,
					sendCopy,
				},
				success( response ) {
					//console.log( response );
					if ( 0 === response.result ) {
						$( '.send-message' ).removeClass( 'is-loading' );
						$( '#needs-validation' ).removeClass( 'was-validated' );
						$( '#needs-validation' ).each( function() {
							this.reset();
						} );
						$( '.block-map .contact-form' ).addClass( 'd-none' );
						$( '.block-map .contact-form-success' ).removeClass( 'd-x' );
					} else {
						$( '.send-message' ).removeClass( 'is-loading' );
						$( '#needs-validation' ).removeClass( 'was-validated' );
						$( '#needs-validation' ).each( function() {
							this.reset();
						} );
						$( '.block-map .contact-form' ).addClass( 'd-none' );
						$( '.block-map .contact-form-error' ).removeClass( 'd-x' );
					}
				},
				// error( error ) {
				// 	console.log( error );
				// },
				// complete( complete ) {

				// },
			} );
		} else {
			event.preventDefault();
			event.stopPropagation();
			$( '#needs-validation' ).addClass( 'was-validated' );
		}
	} );

	$( '#confirmpolicy-contact' ).change(
		function() {
			if ( $( this ).is( ':checked' ) ) {
				$( '.abc-checkbox.contact .invalid-checkbox' ).hide();
			}
		} );

	// Newsletter form
	$( '.newsletter-subscribe' ).on( 'click', function( event ) {
		event.preventDefault();

		const newsletterForm = $( '#newsletter-needs-validation' )[ 0 ];
		const email = $( '.newsletter-email-input' ).val();

		if ( ! $( '#confirmpolicy-newsletter' ).is( ':checked' ) ) {
			$( '.abc-checkbox.newsletter .invalid-checkbox' ).show();
		}

		if ( newsletterForm.checkValidity() === true ) {
			$( '.newsletter-subscribe' ).addClass( 'is-loading' );

			$.ajax( {
				type: 'POST',
				url: etabwebsitejsvars.ajaxurl,
				dataType: 'json',
				data: {
					action: 'etabwebsite_newsletter',
					email,
				},
				success( response ) {
					//console.log( response );
					if ( 0 === response.result ) {
						$( '.newsletter-subscribe' ).removeClass( 'is-loading' );
						$( '#newsletter-needs-validation' ).removeClass( 'was-validated' );
						$( '#newsletter-needs-validation' ).each( function() {
							this.reset();
						} );
						$( '.newsletter-content' ).addClass( 'd-none' );
						$( '.contact-form-success.newsletter-success' ).removeClass( 'd-x' );
						$( '.contact-form-success.newsletter-success h2' ).text( response.message );
						$( 'html, body' ).stop().animate( {
							scrollTop: $( '.block-newsletter' ).offset().top - 75,
						}, 900, function() {

						} );
					} else {
						// Show some error message, get it from response.message
						$( '.newsletter-subscribe' ).removeClass( 'is-loading' );
						$( '#newsletter-needs-validation' ).removeClass( 'was-validated' );
						$( '#newsletter-needs-validation' ).each( function() {
							this.reset();
						} );
						// $('.newsletter-error').removeClass('d-x');
						$( '.submit-wrapper .invalid-feedback' ).show();
						$( '.submit-wrapper .invalid-feedback p' ).text( response.message );
						$( 'html, body' ).stop().animate( {
							scrollTop: $( '.block-newsletter' ).offset().top - 75,
						}, 900, function() {

						} );
					}
				},
				error( error ) {
					//console.log( error );
					$( '.submit-wrapper .invalid-feedback h4' ).text( error.message );
				},
			} );
		} else {
			event.preventDefault();
			event.stopPropagation();
			$( '#newsletter-needs-validation' ).addClass( 'was-validated' );
		}
	} );

	$( '#confirmpolicy-newsletter' ).change( function() {
		if ( $( this ).is( ':checked' ) ) {
			$( '.abc-checkbox.newsletter .invalid-checkbox' ).hide();
		}
	} );

	// show full table
	const $variationTable = $( '.product-variants table' ),
		$variationRows = $variationTable.find( 'tbody tr' ),
		$showMinRows = '3',
		$showMaxRows = $variationRows.length;

	$variationRows.hide().slice( 0, $showMinRows ).show();
	//console.log( $showMaxRows );
	if ( $showMaxRows <= 3 ) {
		$( '.table-link' ).hide();
	}

	function getLocalLanguage() {
		const spanClass = document.querySelector('.aclp-toggle span').className;
		const match = spanClass.match(/flag-icon-(se|gb)/);
		return match ? match[1] : false;
	}

	const minimizeText = getLocalLanguage() === 'se' ? 'Minimera tabellen ' : 'Minimize table ';
	const maximizeText = getLocalLanguage() === 'se' ? 'Visa hela tabellen ' : 'View complete table ';

	$( '.table-link' ).click( function( event ) {
		event.preventDefault();
		$variationRows.slice( $showMinRows, $showMaxRows ).slideToggle( 400, 'linear' );
		$( this ).toggleClass( 'toggled' );

		if ( $( this ).hasClass( 'toggled' ) ) {
			$( this ).text(minimizeText);
		}
		else {
			$( this ).text(maximizeText);
		}

	} );

	// show related content or products
	// Single Product Page - Related content / Products
	$( '#myTab a' ).on( 'click', function( event ) {
		event.preventDefault();
		$( this ).tab( 'show' );
		$( this ).addClass( 'active' ).siblings().removeClass( 'active' );
	} );

	$( '#myTab  a:first-child' ).addClass( 'active' );
	$( '#myTabContent .tab-pane:first-child' ).addClass( 'active' );

	// replacement za custom select
	$( '.custom-selectf' ).each( function() {
		const classes = $( this ).attr( 'class' );

		let selectCategory = 'Select a category';
		if ( 'sv-SE' === document.documentElement.lang ) {
			selectCategory = 'Välj kategori';
		}
		let template = '<div class="' + classes + '">';
		template += '<span class="custom-select-trigger">' + selectCategory + '</span>';
		template += '<div class="custom-options">';

		$( this ).find( 'option' ).each( function() {
			template += '<span class="custom-option ' + $( this ).attr( 'class' ) + '" data-value="' + $( this ).attr( 'value' ) + '">' + $( this ).html() + '</span>';
		} );

		template += '</div></div>';

		$( this ).wrap( '<div class="custom-select-wrapper"></div>' );
		$( this ).hide();
		$( this ).after( template );
	} );

	$( '.custom-option:first-of-type' ).hover( function() {
		$( this ).parents( '.custom-options' ).addClass( 'option-hover' );
	}, function() {
		$( this ).parents( '.custom-options' ).removeClass( 'option-hover' );
	} );

	$( '.custom-select-trigger' ).on( 'click', function() {
		$( 'html' ).one( 'click', function() {
			$( '.custom-selectf' ).removeClass( 'opened' );
		} );
		$( this ).parents( '.custom-selectf' ).toggleClass( 'opened' );
		event.stopPropagation();
	} );

	$( '.custom-option' ).on( 'click', function() {
		$( this ).parents( '.custom-select-wrapper' ).find( 'select' ).val( $( this ).data( 'value' ) );
		$( this ).parents( '.custom-options' ).find( '.custom-option' ).removeClass( 'selection' );
		$( this ).addClass( 'selection' );
		$( this ).parents( '.custom-selectf' ).removeClass( 'opened' );
		$( this ).parents( '.custom-selectf' ).find( '.custom-select-trigger' ).text( $( this ).text() );
		$( '.category-nav' ).trigger( 'change' );
	} );

	// replacement for name sort select
	$( '.products-sort-byname' ).each( function() {
		let sortByName = 'Sort by recommended';
		if ( 'sv-SE' === document.documentElement.lang ) {
			sortByName = 'Sortera på rekommenderad';
		}
		const classes = $( this ).attr( 'class' );
		let template = '<div class="' + classes + '">';
		template += '<span class="custom-select-trigger-name">' + sortByName + '</span>';
		template += '<div class="custom-options-name">';

		$( this ).find( 'option' ).each( function() {
			template += '<span class="custom-option-name ' + $( this ).attr( 'class' ) + '" data-value="' + $( this ).attr( 'value' ) + '">' + $( this ).html() + '</span>';
		} );

		template += '</div></div>';

		$( this ).wrap( '<div class="custom-select-wrapper"></div>' );
		$( this ).hide();
		$( this ).after( template );
	} );

	$( '.custom-option-name:first-of-type' ).hover( function() {
		$( this ).parents( '.custom-options-name' ).addClass( 'option-hover' );
	}, function() {
		$( this ).parents( '.custom-options-name' ).removeClass( 'option-hover' );
	} );

	$( '.custom-select-trigger-name' ).on( 'click', function( event ) {
		$( 'html' ).one( 'click', function() {
			$( '.products-sort-byname' ).removeClass( 'opened' );
		} );
		$( this ).parents( '.products-sort-byname' ).toggleClass( 'opened' );
		event.stopPropagation();
	} );

	$( '.custom-option-name' ).on( 'click', function() {
		$( this ).parents( '.custom-select-wrapper' ).find( 'select' ).val( $( this ).data( 'value' ) );
		$( this ).parents( '.custom-options-name' ).find( '.custom-option-name' ).removeClass( 'selection' );
		$( this ).addClass( 'selection' );
		$( this ).parents( '.products-sort-byname' ).removeClass( 'opened' );
		$( this ).parents( '.products-sort-byname' ).find( '.custom-select-trigger-name' ).text( $( this ).text() );
		$( '.products-sort-byname' ).trigger( 'change' );
	} );

	// menu hover animation, must execute last
	let $el, leftPos, newWidth;
	const $mainNav = $( '.nav-sticky .main-nav' );

	$mainNav.append( '<li class="hover-line"></li>' );
	const $hoverLine = $( '.hover-line' );

	if ( $( '.main-nav li.current-menu-item' ).length ) {
		$hoverLine
			.width( $( '.main-nav li.current-menu-item' ).width() )
			.css( 'left', $( '.main-nav li.current-menu-item' ).position().left )
			.css( 'background', '#01A44F' )
			.data( 'origLeft', $hoverLine.position().left )
			.data( 'origWidth', $hoverLine.width() );
	}

	const device = navigator.userAgent.toLowerCase();
	const ios = device.match( /(iphone|ipod|ipad)/ );

	if ( ! ( ios ) ) {
		$( '.main-nav li:not(.current-menu-item) a' ).hover( function() {
			$el = $( this );
			leftPos = $el.position().left;
			newWidth = $el.parent().width();
			$hoverLine.css( 'background', '#333' );
			$hoverLine.stop().animate( {
				left: leftPos,
				width: newWidth,
			}, 400 );
		}, function() {
			if ( $( '.main-nav li.current-menu-item' ).length ) {
				$hoverLine.stop().animate( {
					left: $hoverLine.data( 'origLeft' ),
					width: $hoverLine.data( 'origWidth' ),
				}, 400 );
				$hoverLine.css( {
					background: '#01A44F',
				} );
			} else {
				$hoverLine.stop().animate( {
					left: 'auto',
					width: 0,
				}, 400 );
			}
		} );
	}

	if ( $( '.archive.tax-product_cat' ).length || $( '.archive.post-type-archive-product' ).length ) {
		scrollToPreviousProduct();
	}

	$( window ).bind( 'pageshow', function( e ) {
		if ( e.originalEvent.persisted ) {
			if ( $( '.archive.tax-product_cat' ).length || $( '.archive.post-type-archive-product' ).length ) {
				window.location.reload();
			}
		}
	} );

	function scrollToPreviousProduct() {
		const product = sessionStorage.getItem( 'last-product' );

		if ( null === product ) {
			return;
		}

		checkIfElementLoaded( product );
	}

	let counter = 0;
	function checkIfElementLoaded( product ) {
		setTimeout( function() {
			const scrollValue = $( '.product-card .title span' ).filter( function() {
				return product === $( this ).text();
			} ).offset();
			if ( ( 'undefined' !== typeof scrollValue ) || counter > 10 ) {
				scrollToProduct( scrollValue );
				return;
			}
			counter++;
			checkIfElementLoaded( product );
		}, 500 );
	}

	function scrollToProduct( scrollValue ) {
		if ( undefined === scrollValue ) {
			return;
		}
		scrollValue = scrollValue.top - 120;
		$( 'html, body' ).stop().animate(
			{ scrollTop: scrollValue },
			900,
			'swing'
		);
	}

	$( 'body' ).on( 'click', '.product-card', function() {
		const productId = $( this ).find( '.title span' ).text();
		const category = $( '.products-nav .list-unstyled li.active a' ).data( 'category-slug' );

		sessionStorage.setItem( 'last-product', productId );
		sessionStorage.setItem( 'last-category', category );
		history.scrollRestoration = 'manual';
	} );

	$( 'header, footer' ).on( 'click', function() {
		clearProductState();
	} );

	function clearProductState() {
		sessionStorage.removeItem( 'offset' );
		sessionStorage.removeItem( 'last-product' );
		sessionStorage.removeItem( 'last-category' );
	}
} );

( function( $ ) {
	// Add scrollspy to <body>
	$( 'body' ).scrollspy( { target: '.page-nav', offset: 200 } );

	// Add smooth scrolling on all links inside the navbar
	$( '.page-nav-item a' ).on( 'click', function( event ) {
		if ( this.hash !== '' ) {
			event.preventDefault();

			const hash = this.hash;
			let offsetnime = $( '.page-nav.stuck' ).outerHeight();

			if ( $( window ).width() < 1199 ) {
				if ( ! $( '.page-nav' ).hasClass( 'stuck' ) ) {
					offsetnime = 320;
				}
				if ( $( '.page-nav' ).hasClass( 'stuck' ) ) {
					offsetnime = 190;
				}
			}
			if ( $( window ).width() < 580 ) {
				offsetnime = 70;
			}
			if ( $( window ).width() > 1199 ) {
				if ( ! $( '.page-nav' ).hasClass( 'stuck' ) ) {
					offsetnime = 270;
				}
				if ( $( '.page-nav' ).hasClass( 'stuck' ) ) {
					offsetnime = 138;
				}
			}
			// hakanje rodjeni
			$( 'html, body' ).animate( {
				scrollTop: $( hash ).offset().top - offsetnime,
			}, 800, function() {

			} );
		}
	} );
}( jQuery ) );

//check browser
( function( $ ) {
	const isOpera = !! window.opera || navigator.userAgent.indexOf( ' OPR/' ) >= 0;
	const isChrome = !! window.chrome && ! isOpera;
	const isSafari = /^((?!chrome|android).)*safari/i.test( navigator.userAgent );

	if ( isSafari && ! isChrome ) {
		$( 'body' ).addClass( 'is-safari' );
	}
}( jQuery ) );

//for sorting and filter table on single-product page
( function() {
	const table = document.getElementById( 'sortable-table' );

	if ( table ) {
		// Table Sort
		const sort = new Tablesort( table );
		sort.refresh();

		// Table Filter

		/**
		 * Get currently selected language from the 'aclp-toggle' language picker.
		 *
		 * @returns {string|boolean} - The currently selected language or false if not 'se' or 'gb'.
		 */
		function getSelectedLanguage() {
			const spanClass = document.querySelector('.aclp-toggle span').className;
			const match = spanClass.match(/flag-icon-(se|gb)/);
			return match ? match[1] : false;
		}

		/**
		 * Check if the table has any of the given header titles and return the column number (index) of the first found.
		 *
		 * @param {HTMLTableElement} table - The table element to check.
		 * @param {Array<string>} headerTitles - The titles to check in the table headers.
		 * @returns {number|boolean} - The column number (index) if any header title exists, or false otherwise.
		 */
		function findHeaderColumnNumber(table, headerTitles) {
			const headerCells = table.querySelector('thead tr').cells;
			for (let i = 0; i < headerCells.length; i++) {
				if (headerTitles.includes(headerCells[i].textContent.trim())) {
					return i; // 0-based index of the column number
				}
			}
			return false;
		}

		// Maybe load up the table filters and get going
		const columnIndex = findHeaderColumnNumber(table, [ 'bredd', 'width' ]);
		const clearFilterText = getSelectedLanguage() === 'se' ? 'Visa: alla bredder' : 'Show: all widths';
		if( false !== columnIndex ) {
			const filterConfig = {
 				[`col_${columnIndex}`]: 'select',
				filters_row_index: 1,
				help_instructions: {
					text : 'Instructions!',
					btn_text : '',
				},
				clear_filter_text: clearFilterText,
			};
			const filter = new TableFilter( table, filterConfig );
			filter.init();
		}
	}
}() );

//display language text link
( function( $ ) {
	$( '#change-language-message' ).html( $( 'li.inactive' ).html() );
}( jQuery ) );

( function( $ ) {
	$( '#change-language' ).hover( function() {
		if ( $( window ).width() > 991 ) {
			$( '#btn-search' ).css( { display: 'none' } );
			$( '#btn-search-sm' ).css( { display: 'none' } );
			// alert("hovered");
		}
	}, function() {
		$( '#btn-search' ).css( { display: 'inline-block' } );
		$( '#btn-search-sm' ).css( { display: 'inline-block' } );
	} );
}( jQuery ) );

( function( $ ) {
	function getCurrentLanguage() {
		const spanClass = document.querySelector('.aclp-toggle span').className;
		const match = spanClass.match(/flag-icon-(se|gb)/);
		return match ? match[1] : false;
	}

	const showFilterText = getCurrentLanguage() === 'se' ? 'Visa: ' : 'Show: ';

	const tableDropdown = $( '.product-variants thead td select[id^="flt"][id$="_sortable-table"]' );

	tableDropdown.on( 'change', function( ) {
		if ( ! tableDropdown.val() ) {
			$( '.single-product .table-link-container' ).show();
		}
		else {
			$( '.single-product .table-link-container' ).hide();

			$('.product-variants thead td select[id^="flt"][id$="_sortable-table"] > option').each(function() {
				if ( ! ( $(this).text().indexOf( showFilterText ) >= 0 ) ) {
					$(this).text( showFilterText + $(this).text());
				}
			});
		}
	} );
}( jQuery ) );
